import Vue from 'vue'
import md5 from 'js-md5';
import VueRouter from 'vue-router'
import routers from './routers'
import App from './App.vue'
import './plugins/element.js'
import {store} from './utils/store'
import {Config, /* Utils,*/ UrlHelper} from './utils/Utils'
import {MessageProxy} from './utils/MessageProxy'

Vue.prototype.$md5 = md5;
// import Vconsole from 'vconsole'
// const vConsole = new Vconsole()

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

Vue.config.productionTip = false

const router = new VueRouter({
    mode: 'hash',
    routes: routers
})

// console.log("main", location.hash)
// console.log(location)
// alert(location.pathname);

// --delete begin by pengliang on 20230127，不使用url参数控制"高级"Tab的显示，改为通过Hospital的”isProfessional"属性控制
// let isPro = UrlHelper.parse('pro')
// console.log(isPro)
// if (isPro != undefined && isPro != '' && isPro != '0' && isPro != 'false') {
//     store.isProfessional = true
// }
// --delete end--

setBaseUrl()

let hospitalShortName = UrlHelper.parse('h')
if (hospitalShortName != undefined && hospitalShortName != '') {
    store.hospital.shortName = hospitalShortName
} else {
    hospitalShortName = UrlHelper.parse('hospital')
    // console.log(hospitalShortName)
    if (hospitalShortName != undefined && hospitalShortName != '') {
        store.hospital.shortName = hospitalShortName
    } else {
        const mainHost = Config.getMainHost()
        if (mainHost && mainHost !== location.hostname) {
            store.hospital.shortName = location.hostname.replace(new RegExp('.' + mainHost), '')
        }
    }
}

if (store.hospital.shortName) {
    MessageProxy.getHospitalInfo(store.hospital.shortName).then((result) => {
        if (true == result.hasError) {
            console.error(result.errorText);
            return;
        }
        if (result.data != null && result.data.code != '200') {
            console.error(result.data.message);
            return;
        }
        if (result.data.data != null) {
            store.hospital.id = result.data.data.id;

            if (result.data.data.image3dServiceUrl != null && result.data.data.image3dServiceUrl != '') {
                store.hospital.image3dServiceUrl = result.data.data.image3dServiceUrl;
            }
            if (result.data.data.enableFlag != undefined && 0 == result.data.data.enableFlag) {
                store.hospital.enableFlag = false;
            }

            if (result.data.data.identityCheckMode != undefined && '' != result.data.data.identityCheckMode) {
                store.hospital.identityCheckMode = result.data.data.identityCheckMode;
            }

            if (result.data.data.professionalFlag != undefined && '' != result.data.data.professionalFlag) {
                store.isProfessional = result.data.data.professionalFlag == 1 ? true : false
            }

            if (result.data.data.useImageProInsteaded != undefined && '' != result.data.data.useImageProInsteaded) {
                store.useImageProInsteaded = result.data.data.useImageProInsteaded == 1 ? true : false
            } else {
                store.useImageProInsteaded = false
            }

            if (result.data.data.verifyPaymentStatusFlag != undefined && '' != result.data.data.verifyPaymentStatusFlag) {
                store.verifyPaymentStatusFlag = result.data.data.verifyPaymentStatusFlag == 1 ? true : false
            } else {
                store.verifyPaymentStatusFlag = false
            }

            if (result.data.data.maintenanceModeFlag != undefined && '' != result.data.data.maintenanceModeFlag) {
                store.maintenanceModeFlag = result.data.data.maintenanceModeFlag == 1 ? true : false
            } else {
                store.maintenanceModeFlag = false
            }
            // console.log('main enableFlag', store.hospital.enableFlag)
        }
    })
}

store.JSAPIPay = true
// Config.isWxClient() // JSAPIPay JSAPI支付方式

//Config.getWxOpenId()


// 写入登录日志，最多尝试 10 次
let tryCount = 0;
let timer = setInterval(() => {
    tryCount++;

    if (tryCount > 10) {
        clearInterval(timer);
    }

    if (store.hospital.shortName) {         //由指定医院进入
        if (store.hospital.id != undefined && store.hospital.id != '') {    //已获取到医院信息
            if (true == store.JSAPIPay) {           //当前是微信环境
                if (store.userId != undefined && store.userId != '') {      //已获取到 OpenID 和用户信息
                    if (location.pathname.endsWith('/index.html')) {        //微信公众号进入
                        store.isAuthenticated = true;

                        addUserLoginLog(store.hospital.id, '微信公众号');   //写入登录日志
                        clearInterval(timer);
                    } else {
                        if (location.hash.startsWith('#/studyinfo/')) {
                            if (location.hash.startsWith('#/studyinfo/getStudyIdByShortCode=')) {    //短信通知进入
                                addUserLoginLog(store.hospital.id, '短信通知');
                                clearInterval(timer);
                            } else {                         //扫描二维码进入
                                addUserLoginLog(store.hospital.id, '扫描二维码');
                                clearInterval(timer);
                            }
                        } else if (location.hash.startsWith('#/studylistV4')) {
                            addUserLoginLog(store.hospital.id, 'studylistV4');
                            clearInterval(timer);
                        } else if (location.hash.startsWith('#/studylistV3')) {
                            addUserLoginLog(store.hospital.id, 'studylistV3');
                            clearInterval(timer);
                        } else if (location.hash.startsWith('#/studylistV2')) {
                            addUserLoginLog(store.hospital.id, 'studylistV2');
                            clearInterval(timer);
                        } else {
                            console.error('不支持的登入方式')
                        }
                    }
                }
            } else {        //普通浏览器环境
                if (location.pathname.endsWith('/index.html')) {        //手动输入URL
                    addUserLoginLog(store.hospital.id, '浏览器进入');
                    clearInterval(timer);
                } else {
                    if (location.hash.startsWith('#/studyinfo/')) {
                        if (location.hash.startsWith('#/studyinfo/getStudyIdByShortCode=')) {    //短信通知进入
                            addUserLoginLog(store.hospital.id, '短信通知');
                            clearInterval(timer);
                        } else {                         //扫描二维码进入
                            addUserLoginLog(store.hospital.id, '扫描二维码');
                            clearInterval(timer);
                        }
                    } else if (location.hash.startsWith('#/studylistV4')) {
                        addUserLoginLog(store.hospital.id, 'studylistV4');
                        clearInterval(timer);
                    } else if (location.hash.startsWith('#/studylistV3')) {
                        addUserLoginLog(store.hospital.id, 'studylistV3');
                        clearInterval(timer);
                    } else if (location.hash.startsWith('#/studylistV2')) {
                        addUserLoginLog(store.hospital.id, 'studylistV2');
                        clearInterval(timer);
                    } else {
                        console.error('不支持的登入方式')
                    }
                }
            }
        }
    } else {        //无指定医院，由公众号进入
        if (true == store.JSAPIPay) {           //当前是微信环境
            addUserLoginLog(null, '微信公众号');
        }
    }

}, 100);

// alert("main isAuthenticated " + store.isAuthenticated)

router.beforeResolve(async (to, from, next) => {
    const urlParam = Config.getUrlParam(to.fullPath).obj
    /* 
        有以下参数的，跳转studyinfo页面
        shareCode-共享码 || token || shortId-短信短码
    */
    let token = urlParam.token;
    let shareCode = urlParam.sharecode
    let shortId = urlParam.id
    const code = shareCode || token || shortId
    if (code && to.name !== 'studyinfo') {
        store.userId = store.userId || code
        let str = Config.resetUrlParam(urlParam, ['shareCode', 'token', 'id'])
        const api = shareCode ? 'getStudyIdByShareCode' : token ? 'getStudyIdByToken' : 'getStudyIdByShortCode'
        next({path: '/studyinfo/' + api + '=' + code + str})
    } else {
        // 2021-8-20 暂时停掉南开医院扫码进入
        if (store.hospital.shortName == 'nkyy' && location.hash.startsWith('#/studyinfo/')) {
            alert("二维码已过期");
            return;
        }

        if (!to.meta.auth) { // 不需要登录
            // if (Utils.isPhone() == false) {
            //     next({ path: '/pclogin' })
            // } else {
            //     next()
            // }
            next()
            return
        }
        if (!store.userId && !store.user.tel) {  // 需要登录
            next({path: '/login'})
            // if (Utils.isPhone() == false) {
            //     next({ name: 'pclogin' })        // 屏蔽此登录页面，防止漏洞扫描不通过
            // } else {
            //     next({ path: '/login' })         // 屏蔽此登录页面，防止漏洞扫描不通过
            // }
        } else {
            next()
        }
    }
})

function setBaseUrl() {
    store.originalUrl = location.href;
    store.hostUrl = location.origin + "/";
    let pagePath = location.pathname.split('/')
    pagePath.pop()
    pagePath = pagePath.join('/')
    store.baseUrl = location.origin + pagePath + "/"
    store.imageUrl = store.baseUrl + "images/";
    let pos = location.href.lastIndexOf("/");
    pos = location.href.indexOf("?");
    store.actualUrl = location.href.substring(0, pos);
}

function addUserLoginLog(hospitalId, loginType) {
    let operation = '';

    // const urlParam = Config.getUrlParam().obj

    // if (Config.isWxClient()) {
    //     operation = `微信浏览器登入`;
    // } else if (urlParam.sharecode || urlParam.token || urlParam.id) {
    //     operation = urlParam.sharecode ? "通过共享码获取检查" : urlParam.token ? "通过'token'获取检查" : "通过短信获取检查";
    //     // loginType = '短信'
    // } else {
    //     operation = "浏览器登入"
    // }

    if ('微信公众号' == loginType) {
        operation = `微信公众号登入 OpenID: ${store.wx.openid}  医院：${store.hospital.shortName}`;
    } else if ('扫描二维码' == loginType) {
        let pos = location.hash.lastIndexOf('/');
        let studyId = location.hash.substr(pos + 1);
        operation = `扫描报告单二维码登入 StudyID: ${studyId}  医院：${store.hospital.shortName}`;
    } else if ('短信通知' == loginType) {
        let pos = location.hash.lastIndexOf('=');
        let shortCode = location.hash.substr(pos + 1);
        operation = `检查完成短信通知登入 ShortCode: ${shortCode}  医院：${store.hospital.shortName}`;
    } else if ('studylistV4' == loginType) {
        operation = `studylistV4  医院：${store.hospital.shortName}`;
    } else if ('studylistV3' == loginType) {
        operation = `studylistV3  医院：${store.hospital.shortName}`;
    } else if ('studylistV2' == loginType) {
        operation = `studylistV2  医院：${store.hospital.shortName}`;
    }

    MessageProxy.addUserLog(store.userId || window.returnCitySN["cip"], operation, loginType, hospitalId).then(() => {
    })
}

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
